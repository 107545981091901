@font-face {
  font-family: 'Light';
  src: url(/static/media/GothamLight.95b0156b.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamLight.55aad5cc.otf) format('opentype'),
    url(/static/media/GothamLight.9fd22939.woff) format('woff'),
    url(/static/media/GothamLight.98d8b122.ttf) format('truetype'),
    url(/static/media/GothamLight.2c9f5056.svg#GothamLight) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bold';
  src: url(/static/media/GothamBold.c7c9bcc7.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamBold.678599da.woff) format('woff'),
    url(/static/media/GothamBold.46e967b8.ttf) format('truetype'),
    url(/static/media/GothamBold.f7f29234.svg#GothamBold) format('svg');
  font-weight: normal;
  font-style: normal;
}

