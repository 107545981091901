@font-face {
  font-family: 'Light';
  src: url('./fonts/GothamLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GothamLight.otf') format('opentype'),
    url('./fonts/GothamLight.woff') format('woff'),
    url('./fonts/GothamLight.ttf') format('truetype'),
    url('./fonts/GothamLight.svg#GothamLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bold';
  src: url('./fonts/GothamBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GothamBold.woff') format('woff'),
    url('./fonts/GothamBold.ttf') format('truetype'),
    url('./fonts/GothamBold.svg#GothamBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
